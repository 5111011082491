import React from "react";
import * as styles from "./bookhead.module.css";

const Bookhead = () => {
  return (
    <div className={styles.mainSection}>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <h1>
            <span>BOOKS</span>
          </h1>
          <p> Home / Books</p>
        </div>
      </div>
    </div>
  );
};

export default Bookhead;
