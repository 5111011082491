import React from "react";
import Home from "../components/books";

const books = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default books;
