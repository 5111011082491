import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import Bookbox from "./bookbox/Bookbox";
import Bookhead from "./header/Bookhead";

const Home = () => {
  return (
    <>
      <Navbar />
      <Bookhead />
      <Bookbox />
      <Footer />
    </>
  );
};

export default Home;
